import { useState, useRef } from 'react';
import classNames from 'classnames';

import { Swiper, SwiperSlide } from 'swiper/react';

import { A11y, Thumbs, FreeMode, Navigation,  Pagination  } from 'swiper/modules';
import { isJson } from '@viome/vnxt-nucleus';
import Image from 'components/Layout/Module/Image';
import Video from 'components/Layout/Module/Video';
import styles from './Gallery.module.scss';

import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/thumbs';
import 'swiper/css/navigation';


export default function Gallery({ data }) {
    const navigationPrevRef = useRef(null),
        navigationNextRef = useRef(null),
        [thumbsSwiper, setThumbsSwiper] = useState(null),
        { items, css } = data,
        playIcon = (
            <svg
                className={styles.video__button}
                alt="player icon - play"
                height="60"
                width="60"
                xmlns="http://www.w3.org/2000/svg"
            >
                <circle cx="30" cy="30" fill="#151833" opacity=".503" r="30" />
                <path
                    d="m24.98 17.5 17.787 10.9a1.875 1.875 0 0 1 0 3.198l-17.787 10.902a1.875 1.875 0 0 1 -2.855-1.598v-21.804a1.875 1.875 0 0 1 2.855-1.598z"
                    fill="#fff"
                />
            </svg>
        ),
        validJsonCSS = isJson(css);
    return (
        <div
            className={classNames(styles.Gallery)}
            style={validJsonCSS ? JSON.parse(css) : {}}
        >
            <Swiper
                thumbs={{ swiper: thumbsSwiper }}
                modules={[A11y, Thumbs, FreeMode, Navigation, Pagination]}
                navigation={{
                    prevEl: navigationPrevRef.current,
                    nextEl: navigationNextRef.current,
                }}
                onBeforeInit={(swiper) => {
                    swiper.params.navigation.prevEl = navigationPrevRef.current;
                    swiper.params.navigation.nextEl = navigationNextRef.current;
                }}
                pagination={{ clickable: true }}
            >
                {items.map((item, index) => {
                    const id = item?.sys.id,
                        moduleType = item.sys.contentType.sys.id,
                        { fields } = item;

                    let moduleContent;

                    if (moduleType === 'moduleImage') {
                        moduleContent = (
                            <div className={styles.Gallery__slideContainer}>
                                <Image
                                    data={fields.src}
                                    mp4={fields.mp4}
                                    options={{
                                        width: fields.displayWidth,
                                        quality: fields.quality,
                                    }}
                                />
                            </div>
                        );
                    } else if (moduleType === 'video') {
                        moduleContent = (
                            <div className={styles.Gallery__slideContainer}>
                                <Video data={module} />
                            </div>
                        );
                    }

                    return (
                        <SwiperSlide
                            className="swiper-slide"
                            key={id}
                            data-index={index}
                        >
                            {moduleContent}
                        </SwiperSlide>
                    );
                })}
            </Swiper>
            <Swiper
                onSwiper={setThumbsSwiper}
                spaceBetween={10}
                slidesPerView={4}
                breakpoints={{
                    1200: {
                        slidesPerView: 5,
                    },
                }}
                modules={[A11y, Thumbs]}
                watchSlidesProgress
            >
                {items.map((item, index) => {
                    const { videoMetaData, videoThumbnail } = item.fields,
                        id = item?.sys.id,
                        moduleType = item.sys.contentType.sys.id,
                        moduleFields = item.fields;

                    let moduleContent;
                    if (moduleType === 'moduleImage') {
                        moduleContent = (
                            <div className={styles.imageThumbnail}>
                                <Image
                                    data={moduleFields.src}
                                    mp4={moduleFields.mp4}
                                    options={{
                                        height: 79,
                                        layout: 'fixed',
                                    }}
                                />
                            </div>
                        );
                    } else if (moduleType === 'video') {
                        if (
                            videoMetaData &&
                            videoMetaData.platform === 'youtube'
                        ) {
                            const { snippet } =
                                    videoMetaData.video_metadata.items &&
                                    videoMetaData.video_metadata.items[0],
                                { thumbnails } = snippet,
                                thumbnailUrl = thumbnails.default.url;

                            moduleContent = (
                                <div>
                                    {playIcon}
                                    <img
                                        alt={item.fields?.name}
                                        src={
                                            videoThumbnail
                                                ? `https:${videoThumbnail.fields?.file?.url}`
                                                : thumbnailUrl
                                        }
                                    />
                                </div>
                            );
                        }
                        if (
                            videoMetaData &&
                            videoMetaData.platform === 'vimeo'
                        ) {
                            const { pictures } = videoMetaData.video_metadata;

                            moduleContent = (
                                <div className={styles.videoThumbnail}>
                                    <div className={styles.playIcon}>
                                        {playIcon}
                                    </div>
                                    <img
                                        alt={item.fields?.name}
                                        src={
                                            videoThumbnail
                                                ? `https:${videoThumbnail.fields?.file?.url}`
                                                : pictures.base_link
                                        }
                                    />
                                </div>
                            );
                        }
                    }

                    return (
                        <SwiperSlide data-index={index} key={id}>
                            {moduleContent}
                        </SwiperSlide>
                    );
                })}
                <div className={styles.CustomNavigation}>
                    <div
                        ref={navigationPrevRef}
                        className={classNames(
                            'swiper-button-prev',
                            styles.CustomNavigation__Arrow,
                            styles.CustomNavigation__Arrow__Prev,
                        )}
                        tabIndex="0"
                        role="button"
                        aria-label="Previous slide"
                    />
                    <div
                        ref={navigationNextRef}
                        className={classNames(
                            'swiper-button-next',
                            styles.CustomNavigation__Arrow,
                            styles.CustomNavigation__Arrow__Next,
                        )}
                        tabIndex="0"
                        role="button"
                        aria-label="Next slide"
                    />
                </div>
            </Swiper>
        </div>
    );
}
