import classNames from 'classnames';
import Image from 'components/Layout/Module/Image';
import Link from 'next/link';
import dynamic from 'next/dynamic';
import React from 'react';
import Popup from 'components/Tailwind/Components/PopupGeneric';
import { InfoIcon as Info } from '@viome/vnxt-nucleus';
import CompareSection from 'components/Compare/CompareSection';
import StyleContextProvider from 'utils/context/StyleContextProvider';
import compareStyles from 'pages/compare/Compare.module.scss';
import Text from '../Text';
import styles from './ProductGrid.module.scss';

export default function ProductGrid({ modules, type, settings }) {
    const CTA = dynamic(import('components/Tailwind/Components/CTA'), {
            ssr: false,
        }),
        PriceWrapper = dynamic(
            import('components/Common/PriceCTA/PriceWrapper'),
            {
                ssr: false,
            },
        ),
        isPlan = type === 'Plans',
        sections = {
            head: [],
        },
        planSections = { head: [] },
        gridSettings = settings.productGrid,
        gridDiscount = gridSettings?.discount || {},
        gridCode = gridSettings?.discountCode || {};

    modules.forEach((module) => {
        const productFields = module?.fields,
            {
                name,
                subtitle,
                slug,
                sku,
                bundleUrl,
                compareImage,
                healthScoresBody,
                productDetails,
            } = productFields,
            imageFields = compareImage?.fields,
            productURL = bundleUrl || `/products/${slug}`,
            productDiscount = gridDiscount[sku],
            productCode = gridCode[sku],
            label = productDetails?.label;

        if (isPlan) {
            planSections.head.push(
                <div
                    className={classNames(styles.head, styles[sku])}
                    key={`${name}head`}
                >
                    <div className={styles.top}>
                        {sku === 'FCS' && (
                            <p
                                className={classNames(
                                    styles.best,
                                    styles['best--plan'],
                                )}
                            >
                                COMPLETE
                                <br />
                                Plan
                            </p>
                        )}
                        <Link href={productURL}>
                            {compareImage && (
                                <Image
                                    data={imageFields}
                                    options={{
                                        height: 100,
                                        priority: true,
                                        quality: imageFields.quality,
                                        align: 'left',
                                        layout: 'responsive',
                                    }}
                                />
                            )}
                            <p
                                className={classNames(
                                    styles.title,
                                    'heading-4 heading',
                                )}
                            >
                                {name}
                            </p>
                            <p
                                className={classNames(
                                    styles.subtitle,
                                    'heading-5 heading',
                                )}
                            >
                                {subtitle}
                            </p>
                        </Link>
                        <p
                            className={classNames(
                                styles.food,
                                'heading-5 heading',
                            )}
                        >
                            <Popup
                                active={false}
                                title={`What's included`}
                                className="heading-5"
                                icon={<Info />}
                            >
                                <StyleContextProvider styles={compareStyles}>
                                    <CompareSection
                                        products={[module]}
                                        selectedItems={[sku]}
                                        sectionOrder={
                                            sku === 'FCS'
                                                ? [
                                                      'title',
                                                      'productDetails.supplements',
                                                      'productDetails.prepro',
                                                      'test',
                                                      'optimizes',
                                                      'food',
                                                      'productDetails.details',
                                                  ]
                                                : [
                                                      'title',
                                                      'productDetails.prepro',
                                                      'test',
                                                      'optimizes',
                                                      'food',
                                                      'productDetails.details',
                                                  ]
                                        }
                                        popup
                                    />
                                </StyleContextProvider>
                            </Popup>
                        </p>
                    </div>
                    <div className={styles.bottom}>
                        <PriceWrapper
                            product={productFields}
                            data={{
                                showCurrency: true,
                                discount: productDiscount,
                            }}
                            isSubscription
                        />
                        <CTA
                            data={{
                                style: sku !== 'FCS' ? 'Standard' : 'Secondary',
                                mode: sku !== 'FCS' ? 'Light' : 'Dark',
                                text: 'Order & Subscribe',
                                pricingMode: 'Light',
                                product: module,
                                parameters:
                                    productCode && `?discount=${productCode}`,
                            }}
                        />
                    </div>
                </div>,
            );
        } else {
            sections.head.push(
                <div
                    className={classNames(styles.head, styles[sku])}
                    key={`${name}head`}
                >
                    <div className={styles.top}>
                        {label && (
                            <p className={classNames(styles.best)}>{label}</p>
                        )}
                        <Link href={productURL}>
                            {compareImage && (
                                <Image
                                    data={imageFields}
                                    options={{
                                        height: 200,
                                        priority: true,
                                        quality: imageFields.quality,
                                        align: 'left',
                                        layout: 'responsive',
                                    }}
                                />
                            )}
                            <p
                                className={classNames(
                                    styles.title,
                                    'heading-4 heading',
                                )}
                            >
                                {name}
                            </p>
                            {subtitle && (
                                <p
                                    className={classNames(
                                        styles.subtitle,
                                        'heading-5 heading',
                                    )}
                                >
                                    {subtitle}
                                </p>
                            )}
                        </Link>
                        <p
                            className={classNames(
                                styles.food,
                                'heading-5 heading',
                            )}
                        >
                            Food &amp; supplement recommendations based on{' '}
                            <Popup
                                active={false}
                                title={`${productDetails?.subscore}+ Integrative Health Scores`}
                                className="heading-5 heading"
                                icon={<Info />}
                            >
                                <StyleContextProvider styles={compareStyles}>
                                    <CompareSection
                                        products={[module]}
                                        selectedItems={[sku]}
                                        sectionOrder={[
                                            'title',
                                            'optimizes',
                                            'children',
                                        ]}
                                        popup
                                    >
                                        <Text
                                            data={{
                                                body: healthScoresBody,
                                                style: 'Left Aligned',
                                            }}
                                        />
                                    </CompareSection>
                                </StyleContextProvider>
                            </Popup>
                        </p>
                    </div>
                    <div className={styles.bottom}>
                        <PriceWrapper
                            product={productFields}
                            data={{
                                showCurrency: true,
                                discount: productDiscount,
                            }}
                        />

                        <CTA
                            data={{
                                style: sku === 'FB' ? 'Secondary' : 'Standard',
                                mode: sku === 'FB' ? 'Dark' : 'Light',
                                text: 'Order Test',
                                product: module,
                                pricingMode: 'Light',
                                pricing: ['Hide Complimentary'],
                                parameters:
                                    productCode && `?discount=${productCode}`,
                            }}
                        />
                    </div>
                </div>,
            );
        }
    });

    const printSection = isPlan ? planSections : sections;

    return (
        <div className={classNames(styles[type], 'mt-10')}>
            {Object.keys(printSection).map((section) => {
                const currentSection = printSection[section];
                return (
                    <div
                        className={classNames(
                            styles.grid,
                            `grid gap-20`,
                            printSection?.head?.length > 3
                                ? `lg:grid-cols-2`
                                : `lg:grid-cols-${modules.length}`,
                        )}
                        key={section}
                    >
                        {currentSection}
                    </div>
                );
            })}
        </div>
    );
}
