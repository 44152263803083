import { useCallback, useRef } from 'react';
import classNames from 'classnames';
import { A11y, Pagination, Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { applyHashMarkToHexCode, parseModules } from '@viome/vnxt-nucleus';
import Image from '../Image';
import Text from '../Text';
import Video from '../Video';
import Row from '../../Row';
import styles from './Carousel.module.scss';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

export default function Carousel({ data }) {
    const {
            items,
            sys,
            showIndicator,
            showNavigation,
            color = '#151833',
            full,
            autoPlay = false,
            autoPlayDelay = 3,
        } = data,
        { id } = sys,
        sliderRef = useRef(null),
        handlePrev = useCallback(() => {
            if (!sliderRef.current) return;
            sliderRef.current.swiper.slidePrev();
        }, []),
        handleNext = useCallback(() => {
            if (!sliderRef.current) return;
            sliderRef.current.swiper.slideNext();
        }, []),
        formattedColor = applyHashMarkToHexCode(color),
        CarouselNavigationArrow = ({ color }) => (
            <svg
                width="19"
                height="30"
                viewBox="0 0 19 30"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M17 2 4 15.722 17 28"
                    stroke={color}
                    strokeWidth="4.333"
                    fill="none"
                    fillRule="evenodd"
                />
            </svg>
        );

    return (
        <div className={classNames(styles.Carousel, full && styles.full)}>
            <Swiper
                // install Swiper modules
                loop
                spaceBetween={50}
                modules={[A11y, Pagination, Autoplay]}
                pagination={
                    showIndicator && {
                        el: `.${styles.indicators}-${id}`,
                        clickable: true,
                        renderBullet: (index, className) =>
                            `<span class="${className} ${
                                styles.indicator
                            }" data-index="${
                                index + 1
                            }" style="background:${formattedColor}"></span>`,
                    }
                }
                autoplay={
                    autoPlay && {
                        delay: autoPlayDelay * 1000,
                        disableOnInteraction: false,
                        pauseOnMouseEnter: true,
                    }
                }
                ref={sliderRef}
            >
                {items.map((item, index) => {
                    const id = item?.sys.id,
                        moduleType = item.sys.contentType.sys.id,
                        { fields } = item;

                    let moduleContent;

                    if (moduleType === 'moduleImage') {
                        moduleContent = (
                            <Image
                                data={fields.src}
                                options={{
                                    maxWidth: fields.displayWidth,
                                    priority: index > 0,
                                    quality: fields.quality,
                                }}
                            />
                        );
                    } else if (moduleType === 'moduleText') {
                        moduleContent = <Text data={fields} />;
                    } else if (moduleType === 'video') {
                        moduleContent = <Video data={fields} />;
                    } else if (moduleType === 'sectionSingleColumn') {
                        moduleContent = (
                            <Row data={parseModules([item])[0]} nested />
                        );
                    }

                    return (
                        <SwiperSlide
                            className="swiper-slide"
                            key={id}
                            data-index={index}
                        >
                            {moduleContent}
                        </SwiperSlide>
                    );
                })}
            </Swiper>
            {showNavigation && (
                <div className={styles.navigation}>
                    <div
                        className={classNames(
                            styles.arrow,
                            styles['arrow--prev'],
                        )}
                        tabIndex="0"
                        role="button"
                        aria-label="Previous slide"
                        onClick={handlePrev}
                        onKeyDown={handlePrev}
                    >
                        <CarouselNavigationArrow color={formattedColor} />
                    </div>
                    <div
                        className={classNames(
                            styles.arrow,
                            styles['arrow--next'],
                        )}
                        tabIndex="0"
                        role="button"
                        aria-label="Next slide"
                        onClick={handleNext}
                        onKeyDown={handleNext}
                    >
                        <CarouselNavigationArrow color={formattedColor} />
                    </div>
                </div>
            )}

            {showIndicator && (
                <div
                    className={classNames(
                        styles.indicators,
                        `${styles.indicators}-${id}`,
                    )}
                />
            )}
        </div>
    );
}
