import { getBackgroundFile } from '@viome/vnxt-nucleus';
import { VIEWPORT_MEDIA_QUERIES } from 'utils/constants';

const RowInlineStyles = ({
    heights = {},
    rowID,
    videoRatio,
    hasHoverVideo,
    bgs = {},
    backgroundQuality,
    pixelDensity,
    // layout = {},
}) => {
    const viewports = Object.keys(VIEWPORT_MEDIA_QUERIES),
        hasBackgroundImg = bgs.XL || bgs.TABLET || bgs.MOBILE,
        // backgroundSettings = layout['background-settings'] || {},
        formatRatio = (ratio) => {
            if (ratio < 1) {
                return ratio * 100;
            }
            return ratio;
        },
        setBG = (viewport, bg, fm) => {
            const imageData =
                    bg ||
                    // Select the next biggest size if viewport size isn't available
                    bgs.XL ||
                    bgs.TABLET ||
                    bgs.MOBILE,
                imageFile = imageData?.fields?.file,
                imageQuality = backgroundQuality ?? 100,
                parsedURL = getBackgroundFile(
                    imageFile,
                    pixelDensity,
                    viewport,
                    fm,
                    imageQuality,
                );

            if (parsedURL) {
                return parsedURL;
            }

            return null;
        };

    let rowStyles = '';

    viewports.forEach((viewport) => {
        rowStyles += `@media only screen and (${
            VIEWPORT_MEDIA_QUERIES[viewport]
        }) {
    .${rowID} {
        ${typeof heights[viewport] === 'string' ? 'padding-top' : `height`}: ${
            heights[viewport]
                ? `${heights[viewport]}${
                      typeof heights[viewport] === 'string' ? '' : `px`
                  }`
                : `auto`
        } !important;
    }
}\n`;
    });

    if (videoRatio && !hasHoverVideo) {
        rowStyles += `.${rowID} {
                padding: 0 0 ${formatRatio(videoRatio)}% 0 !important;
                min-height: ${formatRatio(videoRatio)}vw;
        }\n`;
    } else {
        viewports.forEach((viewport) => {
            const currentBG =
                    hasBackgroundImg && setBG(viewport, bgs[viewport], 'jpg'),
                currentBGAvif =
                    hasBackgroundImg && setBG(viewport, bgs[viewport], 'avif'),
                currentBGWebp =
                    hasBackgroundImg && setBG(viewport, bgs[viewport], 'webp');

            rowStyles += `@media only screen and (${
                VIEWPORT_MEDIA_QUERIES[viewport]
            }) {
    .${rowID} {
        ${currentBG ? `background-image: ${currentBG};` : ''}
    }
    ${
        currentBGWebp
            ? `@supports (background-image: ${currentBGWebp}) {
                .${rowID} {
                    background-image: ${currentBGWebp};
                }
        }`
            : ''
    }
    ${
        currentBGAvif
            ? `@supports (background-image: ${currentBGAvif}) {
            .${rowID} {
                background-image: ${currentBGAvif};
            }
    }`
            : ''
    }
}\n`;
        });
    }
    return <style suppressHydrationWarning>{rowStyles.toString()}</style>;
};

export default RowInlineStyles;
