import { useEffect } from 'react';
import Script from 'next/script';
import styles from './HTML.module.scss';

export default function HTMLEmbed({ data }) {
    const { embedScript } = data,
        embedBody = data.embedHTML;

    useEffect(() => {
        // Reload TrueMed widget
        if (embedBody.includes('truemed')) {
            if (typeof window !== 'undefined') {
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    event: 'truemed_embed',
                });
            }
        }
    }, []);

    return (
        <>
            {embedScript ? <Script src={embedScript} /> : null}
            {embedBody ? (
                <div
                    className={styles.embed}
                    dangerouslySetInnerHTML={{ __html: embedBody }}
                />
            ) : null}
        </>
    );
}
