import { useState } from 'react';
import classNames from 'classnames';
import layoutClasses from 'components/Tailwind/Utils/LayoutClasses';
import styles from './Accordion.module.scss';
import AccordionItem from './AccordionItem';

const styleMap = {
    Generic: styles.generic,
    Icons: styles.icons,
    'Larger Text': styles.large,
    'Icons Collapse': classNames(
        styles.generic,
        styles.icons,
        styles['icons--collapse'],
    ),
    'Icons Start': classNames(styles.icons, styles['icons--start']),
    'CTA Expand': styles.ctaExpand,
    'Default + Side by Side Desktop': styles.sbs,
};

export default function Accordion({ data, darkMode }) {
    const { items, style, fullWidth, borderColor, layout } = data,
        layoutOptions = layoutClasses(layout, borderColor, styles),
        [active, setActive] = useState(new Set()),
        hasSBS = style === 'Default + Side by Side Desktop',
        updateIndex = (index) => {
            const activeIndecies = new Set(hasSBS ? [index] : active);

            if (!hasSBS) {
                if (activeIndecies.has(index)) {
                    activeIndecies.delete(index);
                } else {
                    activeIndecies.add(index);
                }
            }
            setActive(activeIndecies);
        };

    return (
        <div
            className={classNames(
                styles.accordion,
                styleMap[style],
                fullWidth && styles.full,
                darkMode && `${styles.dark}`,
                layoutOptions.classes,
            )}
            style={layoutOptions.styles}
        >
            {items.map((item, index) => (
                <AccordionItem
                    key={Math.random() * index}
                    data={item}
                    itemStyles={layoutOptions.styles}
                    index={index}
                    activeIndecies={active}
                    updateIndex={updateIndex}
                />
            ))}
        </div>
    );
}
