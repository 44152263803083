import { VIEWPORT_MEDIA_QUERIES } from 'utils/constants';

const Widths = ({ widths, itemClass }) => {
    const viewports = Object.keys(VIEWPORT_MEDIA_QUERIES);
    let widthStyles = '';

    if (widths && Object.keys(widths).length > 0) {
        viewports.forEach((viewport) => {
            widthStyles += `@media only screen and (${
                VIEWPORT_MEDIA_QUERIES[viewport]
            }) {
            .${itemClass} {
                width: ${widths[viewport] ? `${widths[viewport]}px` : `auto`};
            }
        }\n`;
        });
    }

    return <style>{widthStyles.toString()}</style>;
};

export default Widths;
