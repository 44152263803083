import classNames from 'classnames';
import Link from 'next/link';
import dynamic from 'next/dynamic';

export default function ProductDetails({
    product = {},
    productDiscount,
    salesMessage,
    styles,
    isPlan,
    isBasic,
    isSection,
    testType,
}) {
    const productFields = product?.fields,
        { name, bundleName, bundleUrl, slug } = productFields,
        productURL = bundleUrl || `/products/${slug}`,
        CTAConfig = ['Hide Complimentary'],
        CTA = dynamic(import('components/Tailwind/Components/CTA'), {
            ssr: false,
        }),
        PriceWrapper = dynamic(
            import('components/Common/PriceCTA/PriceWrapper'),
            {
                ssr: false,
            },
        );

    if (!isBasic) {
        CTAConfig.push('Hide Button');
    }

    return (
        <>
            <Link href={productURL} className={classNames(styles.title)}>
                <p>{bundleName || name}</p>
            </Link>
            {(isSection || testType !== 'Personalized Health Products') && (
                <>
                    <div
                        className={classNames(
                            styles.detailsWrapper,
                            isPlan && styles.plan,
                        )}
                    >
                        {isPlan && <p>From</p>}
                        <PriceWrapper
                            product={productFields}
                            data={{
                                showCurrency: true,
                                discount: productDiscount,
                            }}
                            isSubscription={isPlan}
                        />
                    </div>
                    {isSection ? (
                        <CTA
                            data={{
                                style: 'Standard',
                                mode: 'Dark',
                                text: 'Shop',
                                product,
                                url: productURL,
                                salesMessage,
                            }}
                        />
                    ) : (
                        <CTA
                            data={{
                                style: 'Standard',
                                mode: 'Dark',
                                text: isPlan
                                    ? 'Order & Subscribe'
                                    : 'Order Test',
                                product,
                                pricing: CTAConfig,
                                salesMessage,
                            }}
                        />
                    )}
                </>
            )}
        </>
    );
}
