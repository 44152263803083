import { useContext, useEffect, useState } from 'react';
import classNames from 'classnames';
import dynamic from 'next/dynamic';
import { PromoContext } from 'utils/context/PromoContextProvider';
import { bannerContext } from 'utils/context/DOMContextProvider';
import ReactHtmlParser from 'react-html-parser';
import Selectors from './Selectors';
import styles from './ProductSelector.module.scss';

export default function ProductSelector({ data, settings = {} }) {
    const {
            header,
            product,
            addOnText,
            addOns = [],
            showPriceObservations,
            billingDetails,
        } = data,
        products = {},
        currentProductEl = [],
        addOnProductsEl = [],
        parsedEls = [],
        baseSKUs = [],
        [active, setActive] = useState(product),
        [activeSKUs, setActiveSKUs] = useState(null),
        promoContext = useContext(PromoContext),
        { setBanner } = useContext(bannerContext),
        pageSelectorSettings = promoContext.productSelector || [],
        selectorSettings = settings['product-selector'],
        selectorDiscount =
            selectorSettings?.discount || pageSelectorSettings?.discount || {},
        selectorCode =
            selectorSettings?.discountCode ||
            pageSelectorSettings?.discountCode ||
            {},
        selectorMessage =
            selectorSettings?.message || pageSelectorSettings?.message || [],
        updateCTA = (sku, addOnState) => {
            let updatedSKUs;
            if (addOnState) {
                const currentSKUs = activeSKUs === null ? baseSKUs : activeSKUs;
                updatedSKUs = [sku, ...currentSKUs];
            } else {
                const currentSKUs = activeSKUs === null ? baseSKUs : activeSKUs;
                updatedSKUs = currentSKUs.filter(
                    (activeSKU) => activeSKU !== sku,
                );
            }
            setActiveSKUs(updatedSKUs);
            setActive(products[updatedSKUs.sort().join('|')]);
        },
        CTA = dynamic(import('components/Tailwind/Components/CTA'), {
            ssr: false,
        }),
        // Function to render the addon
        renderAddon = (addOnProduct, addOnItem, addOnItemAttributes, idx) => {
            const productName = addOnItem.bundleName || addOnItem.name;

            // Make sure we only parse each product once
            if (!parsedEls.includes(productName)) {
                parsedEls.push(productName);
                return (
                    <Selectors
                        baseProduct={product}
                        addOnProduct={addOnProduct}
                        addOnItem={addOnItem}
                        addOnItemAttributes={addOnItemAttributes}
                        idx={idx}
                        styles={styles}
                        updateCTA={updateCTA}
                    />
                );
            }
            return null;
        },
        parseProductAddons = (product, idx) => {
            const currentProduct = product?.fields,
                // Determine whether the product has addons or not
                currentAddons = currentProduct?.addons || [product],
                ret = [];

            // Loop through all addons
            currentAddons.forEach((addon) => {
                const addOnItem = addon?.fields,
                    selectorData = addOnItem?.productDetails?.selectorV2,
                    selectorSKU = selectorData[currentProduct.sku],
                    // Get the attributes for the current addon
                    addOnItemAttributes =
                        selectorSKU?.attributes ||
                        selectorData?.attributes ||
                        [],
                    // Render the addon
                    addonEl = renderAddon(
                        currentProduct,
                        addOnItem,
                        addOnItemAttributes,
                        idx,
                    );

                if (addonEl) {
                    if (idx === 0) {
                        currentProductEl.push(addonEl);
                        baseSKUs.push(addOnItem.sku);
                    } else {
                        addOnProductsEl.push(addonEl);
                    }
                }

                ret.push(addOnItem.sku);
            });

            return ret;
        },
        // Loop though product and add-ons to create a list of available products
        availableProducts = () => {
            [product, ...addOns].forEach((currentProduct, idx) => {
                const productSKUs = parseProductAddons(currentProduct, idx);
                products[productSKUs.sort().join('|')] = currentProduct;
            });

            return products;
        };

    // Initiate available products
    availableProducts();

    useEffect(() => {
        setBanner(
            active ? (
                <div className="productBannerWrapper">
                    <p className="productName">
                        {active.fields.name || active.fields.bundleName}
                    </p>
                    <CTA
                        data={{
                            style: 'ForwardArrow',
                            mode: 'Light',
                            text:
                                (activeSKUs || baseSKUs).length > 1
                                    ? 'Order and Subscribe'
                                    : 'Order Test',
                            pricing: [
                                'Show Price',
                                'Show Currency',
                                'Hide Installments',
                                'Hide Sales Message',
                            ],
                            product: active,
                            discountAmount: selectorDiscount[active.fields.sku],
                            discountCodeValue: selectorCode[active.fields.sku],
                            billingDetails,
                        }}
                        position="productBanner"
                    />
                </div>
            ) : null,
        );
        return () => {
            setBanner(null);
        };
    }, [active]);

    return (
        <div className={styles.productSelector}>
            {header && <p className={styles.heading}>{header}</p>}
            {currentProductEl}
            {addOns.length > 0 && (
                <>
                    <p className={styles.heading}>{addOnText}</p>

                    {addOnProductsEl}
                </>
            )}
            <div className={classNames(styles.cta, 'mt-6')}>
                {active ? (
                    <CTA
                        data={{
                            style: 'Standard',
                            mode: 'Dark',
                            text:
                                (activeSKUs || baseSKUs).length > 1
                                    ? 'Order and Subscribe'
                                    : 'Order Test',
                            pricing: [
                                'Show Price',
                                'Show Currency',
                                'Hide Installments',
                            ],
                            product: active,
                            discountAmount: selectorDiscount[active.fields.sku],
                            discountCodeValue: selectorCode[active.fields.sku],
                            salesMessage: selectorMessage[active.fields.sku],
                            showPriceObservations,
                            billingDetails,
                        }}
                    />
                ) : (
                    <p>
                        This bundle isn&apos;t available. Please select another
                        option.
                    </p>
                )}
            </div>
            {active.fields.productDetails?.selector?.ageRestriction &&
            <div className={classNames(styles.ageRestriction, "pt-10")}>
                {ReactHtmlParser(active.fields.productDetails?.selector?.ageRestriction?.richContent)}
            </div>}
        </div>
    );
}
