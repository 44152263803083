import { useCallback, useRef, useState } from 'react';
import classNames from 'classnames';
import {
    A11y,
    Pagination,
    Autoplay,
    EffectFade,
    EffectCreative,
    EffectFlip,
    Thumbs,
} from 'swiper/modules';
import { Swiper } from 'swiper/react';
import { applyHashMarkToHexCode, sendDataLayer } from '@viome/vnxt-nucleus';
import layoutClasses from 'components/Tailwind/Utils/LayoutClasses';
import slides from './Slides';
import Thumbnails from './Thumbnails';
import styles from './Carousel.module.scss';
import 'core-js/features/array/at';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/effect-fade';
import 'swiper/css/effect-creative';
import 'swiper/css/effect-flip';

const CarouselNavigationArrow = ({ color }) => (
    <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect
            x="0.5"
            y="0.5"
            width="39"
            height="39"
            rx="19.5"
            strokeWidth="1"
            strokeLinecap="round"
            fill={color.toLowerCase().includes('ffffff') ? color : '#000000'}
            stroke={color.toLowerCase().includes('ffffff') ? '#000000' : color}
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M22.6263 15.3322C22.4428 15.1258 22.4614 14.8098 22.6678 14.6263C22.8742 14.4428 23.1902 14.4614 23.3737 14.6678L27.3737 19.1678L27.669 19.5L27.3737 19.8322L23.3737 24.3322C23.1902 24.5386 22.8742 24.5572 22.6678 24.3737C22.4614 24.1903 22.4428 23.8742 22.6263 23.6678L25.8866 20L12.5 20C12.2239 20 12 19.7761 12 19.5C12 19.2239 12.2239 19 12.5 19L25.8866 19L22.6263 15.3322Z"
            style={{
                stroke: color.toLowerCase().includes('ffffff')
                    ? '#000000'
                    : color.toLowerCase().includes('000000')
                    ? '#ffffff'
                    : color,
            }}
        />
    </svg>
);

export default function Carousel({ data }) {
    const {
            name,
            items,
            thumbnails,
            sys,
            showIndicator,
            showNavigation,
            color = '#FFF',
            full,
            autoPlay = false,
            autoPlayDelay = 3,
            carouselEffect = '',
            carouselType = 'Default',
            slidesPerView = 'auto',
            slidesPerViewTablet,
            slidesPerViewDesktop,
            slidesPerViewGroup = false,
            centerSlides = false,
            speed = 1000,
            loop = true,
            layoutOptions,
        } = data,
        hasThumbnails = thumbnails && thumbnails.length > 0,
        layout = layoutClasses(layoutOptions, null, styles),
        indicatorLayout =
            (layoutOptions &&
                layoutOptions?.indicators &&
                layoutClasses(layoutOptions?.indicators, null, styles)) ||
            {},
        navigationLayout =
            (layoutOptions &&
                layoutOptions?.navigation &&
                layoutClasses(layoutOptions?.navigation, null, styles)) ||
            {},
        { id } = sys,
        sliderRef = useRef(null),
        [thumbsSwiper, setThumbsSwiper] = useState(null),
        [idx, setIndex] = useState(0),
        [snap, setSnap] = useState(items.length),
        handlePrev = useCallback(() => {
            if (!sliderRef.current) return;
            sliderRef.current.swiper.slidePrev();
            sendDataLayer({
                event: 'carouselClick',
                carouselName: name,
                carouselAction: 'prev',
                carouselIndex: idx + 1,
            });
        }, []),
        handleNext = useCallback(() => {
            if (!sliderRef.current) return;
            sliderRef.current.swiper.slideNext();
            sendDataLayer({
                event: 'carouselClick',
                carouselName: name,
                carouselAction: 'next',
                carouselIndex: idx + 1,
            });
        }, []),
        formattedColor = applyHashMarkToHexCode(color),
        swiperModules = [A11y, Pagination],
        carouselSettings = {
            Default: {
                spaceBetween: slidesPerView === 'auto' ? 0 : 10,
                slidesPerView,
                slidesPerGroup: slidesPerViewGroup
                    ? Math.floor(slidesPerView)
                    : 1,
                centeredSlides: centerSlides,
                loop,
                breakpoints: {
                    768: {
                        slidesPerView: slidesPerViewTablet || slidesPerView,
                        slidesPerGroup: slidesPerViewGroup
                            ? Math.floor(slidesPerViewTablet || slidesPerView)
                            : 1,
                        spaceBetween:
                            (slidesPerViewTablet || slidesPerView) === 'auto'
                                ? 0
                                : 10,
                    },
                    1024: {
                        slidesPerView:
                            slidesPerViewDesktop ||
                            slidesPerViewTablet ||
                            slidesPerView,
                        slidesPerGroup: slidesPerViewGroup
                            ? Math.floor(
                                  slidesPerViewDesktop ||
                                      slidesPerViewTablet ||
                                      slidesPerView,
                              )
                            : 1,
                        spaceBetween:
                            (slidesPerViewDesktop ||
                                slidesPerViewTablet ||
                                slidesPerView) === 'auto'
                                ? 0
                                : 10,
                    },
                },
            },
            Hero: {
                spaceBetween: 0,
                thumbs: { swiper: thumbsSwiper, autoScrollOffset: 1 },
                breakpoints: {
                    1024: {
                        thumbs: { autoScrollOffset: 0 },
                    },
                },
            },
            'Hero-Reversed': {
                loop: true,
            },
            Gallery: {
                spaceBetween: 10,
                thumbs: { swiper: thumbsSwiper },
                breakpoints: {
                    1440: {
                        direction: 'vertical',
                    },
                },
            },
            'Solution-Gallery': {
                spaceBetween: 10,
                thumbs: { swiper: thumbsSwiper },
                breakpoints: {
                    1440: {
                        direction: 'vertical',
                    },
                },
            },
        };

    if (carouselEffect === 'fade') {
        swiperModules.push(EffectFade);
    } else if (carouselEffect === 'creative') {
        swiperModules.push(EffectCreative);
    } else if (carouselEffect === 'flip') {
        swiperModules.push(EffectFlip);
    }

    if (autoPlay) {
        swiperModules.push(Autoplay);
    }

    if (
        carouselType.includes('Gallery') ||
        (carouselType === 'Hero' && hasThumbnails)
    ) {
        swiperModules.push(...[Thumbs]);
    }

    return (
        <div
            className={classNames(
                styles.Carousel,
                full && styles.full,
                styles[carouselType.replace(' ', '')],
                hasThumbnails && styles.hasThumbnails,
                formattedColor &&
                    formattedColor.toLowerCase().includes('fff') &&
                    styles.dark,
                layout.classes,
                layout.rowClasses,
            )}
        >
            <Swiper
                // install Swiper modules
                modules={swiperModules}
                pagination={
                    showIndicator && {
                        el: `.${styles.indicators}-${id}`,
                        clickable: true,
                        renderBullet: (index, className) =>
                            `<span class="${className} ${
                                styles.indicator
                            }" data-index="${
                                index + 1
                            }" style="background:${formattedColor}" data-carouselname="${name}"></span>`,
                    }
                }
                autoplay={
                    autoPlay && {
                        delay: autoPlayDelay * 1000,
                        disableOnInteraction: false,
                    }
                }
                effect={carouselEffect}
                fadeEffect={{
                    crossFade: true,
                }}
                speed={speed}
                longSwipesRatio={0.1}
                ref={sliderRef}
                {...(carouselSettings[carouselType] ||
                    carouselSettings.Default)}
                className={styles.main}
                onSlideChange={(e) => {
                    e.el.querySelectorAll('.heroCarousel').forEach((slide) => {
                        const slideClasses = slide.classList;

                        if (!slideClasses.contains('heroCarousel__animate')) {
                            slide.classList.add('heroCarousel__animate');
                        }
                    });
                    setSnap(e.snapGrid.length);
                    setIndex(e.snapIndex);
                    if (!autoPlay) {
                        sendDataLayer({
                            event: 'carouselClick',
                            carouselName: name,
                            carouselAction: 'change',
                            carouselIndex: e.snapIndex + 1,
                        });
                    }
                }}
                onInit={(e) => {
                    setSnap(e.snapGrid.length);
                    setIndex(e.snapIndex);
                }}
                breakpoint={(e) => {
                    setSnap(e.snapGrid.length);
                    setIndex(e.snapIndex);
                }}
            >
                {slides(
                    items,
                    carouselType,
                    slidesPerViewDesktop,
                    slidesPerViewGroup,
                )}
            </Swiper>
            {showNavigation && (
                <div
                    className={classNames(
                        styles.navigation,
                        navigationLayout.classes,
                    )}
                    style={navigationLayout?.styles}
                >
                    {((carouselType === 'Hero' && !hasThumbnails) ||
                        carouselType.includes('Gallery') ||
                        carouselType === 'IPhone' ||
                        carouselType === 'Background' ||
                        carouselType === 'Multi' ||
                        carouselType === 'Card' ||
                        carouselType === 'Testimonials') && (
                        <p className={classNames(styles.count)}>
                            {idx + 1} / {snap}
                        </p>
                    )}
                    {carouselType !== 'IPhone' && (
                        <>
                            <div
                                className={classNames(
                                    styles.arrow,
                                    styles['arrow--prev'],
                                    idx === 0 && !loop && styles.disabled,
                                )}
                                tabIndex="0"
                                role="button"
                                aria-label="Previous slide"
                                onClick={
                                    idx === 0 && !loop ? () => {} : handlePrev
                                }
                                onKeyDown={
                                    idx === 0 && !loop ? () => {} : handlePrev
                                }
                            >
                                <CarouselNavigationArrow
                                    color={formattedColor}
                                />
                            </div>
                            <div
                                className={classNames(
                                    styles.arrow,
                                    styles['arrow--next'],
                                    (idx === items.length - 1 ||
                                        idx === snap - 1) &&
                                        !loop &&
                                        styles.disabled,
                                )}
                                tabIndex="0"
                                role="button"
                                aria-label="Next slide"
                                onClick={
                                    (idx === items.length - 1 ||
                                        idx === snap - 1) &&
                                    !loop
                                        ? () => {}
                                        : handleNext
                                }
                                onKeyDown={
                                    (idx === items.length - 1 ||
                                        idx === snap - 1) &&
                                    !loop
                                        ? () => {}
                                        : handleNext
                                }
                            >
                                <CarouselNavigationArrow
                                    color={formattedColor}
                                />
                            </div>
                        </>
                    )}
                </div>
            )}

            {showIndicator && (
                <div
                    className={classNames(
                        styles.indicators,
                        `${styles.indicators}-${id}`,
                        indicatorLayout.classes,
                        indicatorLayout.classes && styles.reset,
                    )}
                    style={indicatorLayout?.styles}
                />
            )}

            {(carouselType.includes('Gallery') ||
                (carouselType === 'Hero' && hasThumbnails)) && (
                <Thumbnails
                    carouselType={carouselType}
                    thumbsSwiper={thumbsSwiper}
                    setThumbsSwiper={setThumbsSwiper}
                    items={thumbnails || items}
                >
                    {slides(thumbnails || items, carouselType, null, null, 1)}
                </Thumbnails>
            )}
        </div>
    );
}
