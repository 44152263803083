import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import ReactPlayer from 'react-player/lazy';
import formatISODuration from 'date-fns/formatISODuration';
import useTabActive from 'components/Tailwind/Utils/useTabActive';
import Text from 'components/Tailwind/Module/Text';
import layoutClasses from 'components/Tailwind/Utils/LayoutClasses';
import styles from './Video.module.scss';

export default function Video({ data }) {
    const playerRef = useRef(),
        tabActive = useTabActive(),
        [played, setPlayed] = useState(0),
        [watchedPercent, setWatchedPercent] = useState({
            25: false,
            50: false,
            75: false,
            100: false,
        }),
        {
            autoplay,
            headline,
            url,
            videoThumbnail,
            externalVideoUrl,
            videoMetaData,
            useNarrowHeight,
            loop = false,
            showControls = true,
            aspectRatio = 0.625,
            caption,
            layout,
        } = data,
        [playing, setPlaying] = useState(autoplay),
        playIcon = (
            <svg
                className={styles.video__button}
                alt="player icon - play"
                width="64"
                height="64"
                viewBox="0 0 64 64"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <rect
                    x="0.5"
                    y="0.5"
                    width="63"
                    height="63"
                    rx="31.5"
                    stroke="white"
                />
                <path d="M48 32L24 47L24 17L48 32Z" fill="white" />
            </svg>
        ),
        getThumbnail = (autoplay, videoThumbnail, externalVideoUrl) => {
            // Autoplay is false => Light mode with various thumbnail
            // options depending on Contentful values
            if (!autoplay) {
                if (videoThumbnail) {
                    const url = new URL(
                            `https:${videoThumbnail.fields?.file?.url}`,
                        ),
                        srcWithoutHost = url.pathname;

                    return `/cdn${srcWithoutHost}?w=1200`;
                }
                if (externalVideoUrl) {
                    return externalVideoUrl;
                }
                return true;
            }
            // Autoplay is true => regular mode
            return false;
        },
        playerSettings = {
            light: getThumbnail(autoplay, videoThumbnail, externalVideoUrl),
            url,
            controls: showControls,
            playing,
            autoPlay: autoplay,
            muted: autoplay,
            width: '100%',
            height: '100%',
            playIcon,
            loop,
        },
        layoutOptions = layoutClasses(layout, null, styles, null, null);
    let scriptTag = '',
        videoAspectRatio = aspectRatio;

    if (useNarrowHeight) {
        videoAspectRatio = 0.5625;
    }

    const scriptData = {
        name: '',
        description: '',
        thumbnailUrl: '',
        uploadDate: '',
        duration: '',
        embedUrl: '',
        height: '',
        width: '',
    };
    if (videoMetaData && videoMetaData.platform === 'vimeo') {
        const {
            name,
            description,
            pictures,
            release_time: releaseTime,
            duration,
            link,
            width,
            height,
        } = videoMetaData.video_metadata;

        scriptData.name = name;
        scriptData.description = description;
        scriptData.thumbnailUrl = pictures?.base_link;
        scriptData.uploadDate = releaseTime;
        scriptData.duration = duration;
        scriptData.embedUrl = link;
        scriptData.width = width;
        scriptData.height = height;
    }

    if (videoMetaData && videoMetaData.platform === 'youtube') {
        const { contentDetails, snippet } =
                videoMetaData.video_metadata.items &&
                videoMetaData.video_metadata.items[0],
            { title, description, thumbnails, publishedAt } = snippet,
            { duration } = contentDetails;
        scriptData.name = title;
        scriptData.description = description;
        scriptData.thumbnailUrl = thumbnails.default.url;
        scriptData.uploadDate = publishedAt;
        scriptData.duration = duration;
        scriptData.embedUrl = videoMetaData.video_url;
    }

    scriptTag = `<script type="application/ld+json">
            {
            "@context": "https://schema.org",
            "@type": "VideoObject",
            "name": "${scriptData.name || ''}",
            "description": "${scriptData.description || ''}",
            "thumbnailUrl": "${scriptData.thumbnailUrl || ''}",
            "uploadDate": "${scriptData.uploadDate || ''}",
            "duration": "${
                formatISODuration({ seconds: scriptData.duration }) || ''
            }",
            "embedUrl": "${scriptData.embedUrl || ''}",
            "height": "${scriptData.height || ''}",
            "width": "${scriptData.width || ''}",
            "author": [
                { "name": "VIOME INC." ,
                  "@type": "Organization" }
                ]
            }
            </script>`;

    if (url.includes('vimeo')) {
        playerSettings.config = {
            vimeo: {
                playerOptions: {
                    byline: true,
                    portrait: true,
                    title: true,
                    responsive: true,
                    autoplay,
                    muted: autoplay,
                },
            },
        };
    } else if (url.includes('youtube')) {
        playerSettings.config = {
            youtube: {
                playerVars: {
                    autoplay: autoplay ? 1 : 0,
                },
            },
        };
    }
    const sendDataLayer = (event, title) => {
            if (typeof window !== 'undefined') {
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    event: 'video_event',
                    videoTitle: title,
                    videoValue: played,
                    videoEventType: event,
                });
            }
        },
        handleVideoEvent = (event) => {
            if (!loop) {
                sendDataLayer(event, data?.name || 'Unknown');
                if (event === 'Play') {
                    setPlayed(1);
                }
            }
        },
        handleOnProgress = (progress) => {
            const roundedPlayed = Math.floor(progress.played * 100);
            setPlayed(roundedPlayed);

            if (roundedPlayed >= 25 && !watchedPercent[25]) {
                handleVideoEvent(`25% watched`);
                setWatchedPercent({
                    ...watchedPercent,
                    25: true,
                });
            }
            if (roundedPlayed >= 50 && !watchedPercent[50]) {
                handleVideoEvent(`50% watched`);
                setWatchedPercent({
                    ...watchedPercent,
                    50: true,
                });
            }
            if (roundedPlayed >= 75 && !watchedPercent[75]) {
                handleVideoEvent(`75% watched`);
                setWatchedPercent({
                    ...watchedPercent,
                    75: true,
                });
            }
        },
        handleOnEnded = () => {
            if (!loop) {
                playerRef.current.showPreview();
                setPlayed(0);
            }
            handleVideoEvent(`100% watched`);
            setWatchedPercent({
                ...watchedPercent,
                25: false,
                50: false,
                75: false,
                100: false,
            });
        };
    useEffect(() => {
        setPlaying(tabActive);
    }, [tabActive]);

    const handleTabClose = () => {
        handleVideoEvent(`Closed`);
        window.removeEventListener('beforeunload', handleTabClose);
    };
    useEffect(() => {
        window.addEventListener('beforeunload', handleTabClose);

        return () => {
            window.removeEventListener('beforeunload', handleTabClose);
        };
    }, [played]);

    return (
        <div
            className={classNames(styles.video, layoutOptions.classes)}
            style={layoutOptions.styles}
        >
            {headline && <h3 className={styles.video__header}>{headline}</h3>}
            <div
                className={classNames([
                    styles.video__wrapper,
                    useNarrowHeight && styles['video__wrapper-narrow'],
                    videoAspectRatio > 1 && styles.tall,
                ])}
                style={{
                    paddingTop: `${videoAspectRatio * 100}%`,
                }}
            >
                <ReactPlayer
                    ref={playerRef}
                    {...playerSettings}
                    onPlay={() => handleVideoEvent('Play')}
                    onPause={() => handleVideoEvent('Pause')}
                    onProgress={(progress) => handleOnProgress(progress)}
                    onEnded={() => handleOnEnded()}
                />
            </div>
            {videoMetaData && videoMetaData.video_metadata !== '' && (
                <div dangerouslySetInnerHTML={{ __html: scriptTag }} />
            )}
            {caption && (
                <div
                    className={classNames(
                        styles.text__wrapper,
                        played > 0 && styles.playing,
                    )}
                >
                    <Text data={caption.fields} />
                </div>
            )}
        </div>
    );
}
