import {
    applyHashMarkToHexCode,
    CircleCheckThinIcon as CircleCheckThin,
    CheckIcon as Check,
    CheckThinIcon as CheckThin,
    DashIcon as Dash,
    XIcon as X,
    CircleIcon as Circle,
    TriangleIcon as Triangle,
} from '@viome/vnxt-nucleus';

import { SCORE_BULLETS } from 'utils/constants';

const bullets = (color, label) => ({
        check: <Check color={color} />,
        checkThin: <CheckThin color={color} />,
        dash: <Dash />,
        x: <X />,
        circle: <Circle />,
        triangle: <Triangle />,
        'circleCheck-thin': <CircleCheckThin color={color} />,
        plus: (
            <svg
                height="13"
                viewBox="0 0 13 13"
                width="13"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="m29.706 5994.876v-4.441h4.467v-3.066h-4.467v-4.467h-3.066v4.467h-4.467v3.066h4.467v4.441z"
                    fill={color}
                    transform="translate(-22 -5982)"
                />
            </svg>
        ),
        circleCheck: (
            <svg
                width="28"
                height="28"
                viewBox="0 0 28 28"
                xmlns="http://www.w3.org/2000/svg"
            >
                <g
                    stroke={color}
                    strokeWidth="3"
                    fill="none"
                    fillRule="evenodd"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <path d="M2 14c0 6.627 5.373 12 12 12s12-5.373 12-12S20.627 2 14 2 2 7.373 2 14h0z" />
                    <path d="m19.348 10.51-5.81 7.748a1.5 1.5 0 0 1-2.26.16l-3-3" />
                </g>
            </svg>
        ),
        scoreBullets: <img src={SCORE_BULLETS[label]} alt={label} />,
        circleStrikethrough: (
            <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <circle cx="10" cy="10" r="6.5" stroke={color} fill="none" />
                <line
                    x1="5.64645"
                    y1="14.6464"
                    x2="14.6464"
                    y2="5.64645"
                    stroke={color}
                />
            </svg>
        ),
        colored: (
            <svg
                width="16"
                height="17"
                viewBox="0 0 16 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <circle cx="8" cy="8.625" r="7" fill={color} />
            </svg>
        ),
    }),
    Bullet = ({ type, color = '000000', label }) =>
        type
            ? bullets(applyHashMarkToHexCode(color), label)[type] || null
            : null;

export default Bullet;
