import ProductLabels from './ProductLabels';
import ProductImage from './ProductImage';
import ProductDescription from './ProductDescription';
import styles from './ProductsBody.module.scss';

export default function ProductHeader({
    productFields,
    isPlan,
    isModule,
    isSection,
}) {
    const {
            name,
            bundleDescription,
            productDetails = {},
            salesMessage,
            shopifySubscriptionPlanProd,
            shopifySubscriptionPlanStaging,
        } = productFields,
        currentIsPlan =
            isPlan ||
            shopifySubscriptionPlanProd ||
            shopifySubscriptionPlanStaging;

    return (
        <div className={styles.top}>
            {!isSection && (
                <ProductLabels
                    productName={name}
                    productDetails={productDetails}
                    styles={styles}
                    hasSale={salesMessage}
                />
            )}
            <ProductImage
                productFields={productFields}
                styles={styles}
                isPlan={currentIsPlan}
                isModule={isModule}
            />
            {!isSection && (
                <ProductDescription
                    bundleDescription={bundleDescription}
                    styles={styles}
                />
            )}
        </div>
    );
}
