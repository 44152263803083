import classNames from 'classnames';
import Image from 'components/Layout/Module/Image';
import Text from 'components/Tailwind/Module/Text';
import styles from './Person.module.scss';

export default function Person({ data, layout = 'default' }) {
    const { headshot, name, role, bio } = data;

    return (
        <div className={classNames(styles.person, styles[layout])}>
            <Image
                data={headshot}
                options={{
                    width: 96,
                    quality: 100,
                }}
            />
            <div className={styles.bio}>
                <h4 className={styles.name}>{name}</h4>
                {role && <p className={styles.role}>{role}</p>}
                {layout !== 'default' && (
                    <Text
                        data={{
                            body: bio,
                            style: 'Center',
                        }}
                    />
                )}
            </div>
        </div>
    );
}
