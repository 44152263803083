export default function App({ src, cls }) {
    return (
        <div
            className={cls}
            dangerouslySetInnerHTML={{
                __html: `
                            <video
                            loop
                            muted
                            autoplay
                            playsinline
                            preload="metadata"
                            >
                                <source src="${src}" type="video/mp4" />
                            </video>`,
            }}
        />
    );
}
