import classNames from 'classnames';
import Modules from 'components/Tailwind/Modules';
import AccordionItem from 'components/Tailwind/Module/Accordion/AccordionItem';

export default function Selectors({
    index,
    module,
    active,
    interactionAttrs,
    styles,
}) {
    const { name, __typename } = module[0],
        attrs = {
            className: classNames(
                styles.selector,
                active === index && styles.active,
            ),
            ...interactionAttrs(index),
            role: 'button',
            tabIndex: '0',
            'aria-controls': name,
            'aria-expanded': active > -1,
        };

    return (
        <div {...attrs}>
            {__typename === 'componentBeforeAfter' ? (
                <AccordionItem
                    key={Math.random() * index}
                    data={{ fields: module[0] }}
                    index={index}
                    activeIndecies={new Set([active])}
                    updateIndex={interactionAttrs}
                />
            ) : (
                <Modules modules={module} />
            )}
        </div>
    );
}
