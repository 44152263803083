import CTA from 'components/Tailwind/Components/CTA';
import dynamic from 'next/dynamic';
import InlineText from './inlineText';

const InlineBlock = (node, styles) => {
    const blockMetaData = node.data.target.sys,
        blockType = blockMetaData.contentType?.sys?.id,
        blockData = node.data.target.fields,
        Countdown = dynamic(
            import('components/Shell/CountdownTimer/CountdownTimer'),
            {
                ssr: false,
            },
        );

    let blockContent = <div />;

    if (blockType === 'componentCta') {
        blockContent = <CTA data={blockData} />;
    } else if (blockType === 'inlineText') {
        blockContent = InlineText(node, styles, false);
    } else if (blockType === 'componentCountdownTimer') {
        blockContent = (
            <Countdown
                data={blockData}
                clockID={`${blockMetaData.id}${Math.floor(
                    Math.random() * 100,
                )}`}
            />
        );
    } else {
        const { file, title } = blockData;

        blockContent = <img src={file.url} alt={title} />;
    }
    return blockContent;
};

export default InlineBlock;
